import React from 'react';

const CounterClockwiseIcon = (props) => {
    let {width = "20", height = "20", className = "", fill = "#000000", ...reset} = props;
    return (<>
        <svg xmlns="http://www.w3.org/2000/svg" width={`${width}px`} height={`${height}px`}
             className={`${className} icon icon-counterclockwise`}
             viewBox="0 0 24 24" fill="none"
             {...reset}>
            <path
                d="M12 3C7.02944 3 3 7.02944 3 12C3 15.0777 4.55928 17.865 7.0231 19.5009L5.25 19.5C4.83579 19.5 4.5 19.8358 4.5 20.25C4.5 20.6297 4.78215 20.9435 5.14823 20.9932L5.25 21H9.25C9.6297 21 9.94349 20.7178 9.99315 20.3518L10 20.25V16.25C10 15.8358 9.66421 15.5 9.25 15.5C8.8703 15.5 8.55651 15.7822 8.50685 16.1482L8.5 16.25L8.49903 18.635C6.07593 17.3557 4.5 14.8247 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12C19.5 12.4142 19.8358 12.75 20.25 12.75C20.6642 12.75 21 12.4142 21 12C21 7.02944 16.9706 3 12 3ZM12 9.25C10.4812 9.25 9.25 10.4812 9.25 12C9.25 13.5188 10.4812 14.75 12 14.75C13.5188 14.75 14.75 13.5188 14.75 12C14.75 10.4812 13.5188 9.25 12 9.25ZM12 10.75C12.6904 10.75 13.25 11.3096 13.25 12C13.25 12.6904 12.6904 13.25 12 13.25C11.3096 13.25 10.75 12.6904 10.75 12C10.75 11.3096 11.3096 10.75 12 10.75Z"
                fill={fill}/>
        </svg>
{/*        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 76 76" xmlns="http://www.w3.org/2000/svg"*/}
{/*             className={`${className} icon icon-counterclockwise`}*/}
{/*             version="1.1" baseProfile="full"*/}
{/*             enableBackground="new 0 0 76.00 76.00" xml:space="preserve"*/}
{/*             {...reset}*/}
{/*        >*/}
{/*	    <path fill={fill} fillOpacity="1" strokeWidth="0.2" strokeLinejoin="round"*/}
{/*              d="M 58,33.5001L 58,27L 49,19L 40,27.5001L 40,33.5001L 46,28.2097L 46,40.5C 46,46.299 41.299,51 35.5,51C 29.701,51 25,46.299 25,40.5C 25,34.8686 29.4332,30.2727 35,30.0117L 35,24.0074C 26.1186,24.2718 19,31.5546 19,40.5C 19,49.6127 26.3873,57 35.5,57C 44.6127,57 52,49.6127 52,40.5L 52,28.125L 58,33.5001 Z "/>*/}
{/*</svg>*/}
    </>);
};

export default CounterClockwiseIcon;