export const getModalMaterialInfo = (state) => state?.modal?.materialInfo;
export const getModalPlaneOperation = (state) => state?.modal?.planeOperations;
export const getAddClientMaterial = (state) => state?.modal?.addClientMaterial?.isOpen

export const getMaterialProjectIsOpen = state => state?.modal?.materialsProject?.isOpen || false;

export const getModalTemplatePazIsOpen = state => state?.modal?.templatePaz?.isOpen || false;
export const getModalTemplatePazPart = state => state?.modal?.templatePaz?.part || null;

export const getModalBatchMachiningModal = state => state?.modal?.batchMachiningHoles;
export const getModalPostForming = state => state?.modal?.postforming || {isOpen: false, list: null};
export const getModalPacked = state => state?.modal?.packedModal || {isOpen: false};

export const getWarningModalSelected = state=> state?.modal.warningModal;
export const getCreateProjectModalSelected = state=> state?.modal?.createProject;

export const getPartGoodsServiceModalSelected = state=> state?.modal?.order_part_goods_service;

export const getPartIdFromModalOperation = state => state.modal.operations.part;

export const getOperationFrezStartMenuModal = state => state.modal.operationFrezStartMenu;
export const getTroughCreateOperationModal = state => state.modal.troughCreateOperation;
export const getImportOperationCreateModal = state => state.modal.frezImportOperationCreate;
