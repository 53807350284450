import React from 'react';
import right from "./trajectoryRight.png"

interface Props {
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
}

const TrajectoryRightIcon :React.FC<Props>= (props): JSX.Element | null  => {
    return (
        <img src={right} alt="" {...props}/>
    );
};

export default TrajectoryRightIcon;