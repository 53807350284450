import React from 'react';
import {IGetT} from "../../../Boxes/interfaces";
import {useFormik} from "formik";

interface IProps {
    onSubmit: (values: IValuesForm) => void;
    getT:IGetT,
    productionRangeSizeMaterialClient: {min: number, max: number}
    initialValues: IValuesForm
}
interface IValuesForm {
    x: number | string;
    y: number | string;
    count: number | string;
    forSale: number | string;
}
const FormAddRestMaterial :React.FC<IProps>= ({initialValues, onSubmit, getT, productionRangeSizeMaterialClient}) => {

    const formik = useFormik<IValuesForm>({
        initialValues: {
            x: initialValues.x,
            y: initialValues.y,
            count: initialValues.count,
            forSale:initialValues.forSale,
        }, enableReinitialize: true ,
        onSubmit:(values) => {
            if(!values.forSale) {
                values.forSale = values.count;
            }
            onSubmit(values);
        }
    })

    return (
       <form id="add-materials-parts-pieces-form" onSubmit={formik.handleSubmit}
                         className={'add-materials-parts-pieces-form'}
        >
            <div className="form-group-rx-sm-02  form-group-rx-md-03">
                <div className="form-field">


                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Длина")}:
                    </label>
                    <input
                        type="number"
                        name={"x"}
                        className="form-input-sm"
                        min={productionRangeSizeMaterialClient.min}
                        max={productionRangeSizeMaterialClient.max}
                        step={1}
                        placeholder=""
                        value={formik.values.x}
                        onChange={formik.handleChange}
                        required={true}
                    />
                </div>

                <div className="form-field">
                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Ширина")}:
                    </label>
                    <input
                        type="number"
                        name={"y"}
                        className="form-input-sm"
                        min={productionRangeSizeMaterialClient.min}
                        max={productionRangeSizeMaterialClient.max}
                        step={1}
                        placeholder=""
                        value={formik.values.y}
                        onChange={formik.handleChange}
                        required={true}
                    />
                </div>

                <div className="form-field">
                    <label className="label label-sm" htmlFor="material-type">
                        {getT("Количество")}:
                    </label>
                    <input
                        type="number"
                        name={"count"}
                        className="form-input-sm"
                        min={1}
                        step={1}
                        placeholder=""
                        value={formik.values.count}
                        onChange={formik.handleChange}
                        required={true}
                    />
                </div>
            </div>
            <div className="form-field" style={{marginTop: '15px'}}>
                <input className="form-input submit" type="submit" value={getT('Добавить')}/>
            </div>
        </form>
    );
};

export default FormAddRestMaterial;