import {PartPostprocessorFileApi} from "../../api/External/PartPostprocessorFileApi";
import {machineToolPostProcessorFilesSetListAC} from "../actions/machine-tool-post-processor-files-action";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";
import ApiService from "../../api/api";

export const getMachineToolPostProcessorFilesThunk = () => async (dispatch: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const result = await PartPostprocessorFileApi.getListOfMachines();

        if (result?.hasOwnProperty('error') && result?.error && result?.error?.length > 0) {
            dispatch(machineToolPostProcessorFilesSetListAC(null));
            dispatch(dispatcherErrorThunk(result));
            return;
        }
        dispatch(machineToolPostProcessorFilesSetListAC(result.data));
        dispatch(changeLoaderStatusAC(false));

    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}
const api = new ApiService();
export const getPostProcessorFileByMachineToolFormat = (
    machineToolId: string,
    ppFormat: string,
    partId: string | number,
) => async (dispatch: any, getState: any) => {
    try {
        let order = getState().order.order;
        const version = getState().commons.data.version;
        dispatch(changeLoaderStatusAC(true));
        await api.saveProject(version, order);
        // await dispatch(saveOrderProjectDataThunk(false, false, false))
        let result = await PartPostprocessorFileApi.getPostProcessorLink(machineToolId, ppFormat, partId, order.id);
        if (result?.hasOwnProperty('error') && result?.error && result?.error?.length > 0) {
            dispatch(dispatcherErrorThunk(result));
            return;
        }
        if (result.data?.link) {
            console.log(result.data);
            window.open(result.data.link, '_blank'); // Открыть ссылку в новом окне
        }
        dispatch(changeLoaderStatusAC(false));

    } catch
        (e) {
        dispatch(dispatcherErrorThunk(e));

    }
}