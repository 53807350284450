import React, {Component} from 'react';
import Button from "./ui/Button";

class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = {hasError: false, error: null, errorInfo: null};
    }

    static getDerivedStateFromError(error) {
        console.log(error)
        // Update state so the next render will show the fallback UI.
        return {hasError: true, error};
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.log(error, errorInfo);
        console.log(error.stack);
    }

    handlerReloadPage = () => {
        // window.location.reload();
        this.setState({hasError: false, error: null, errorInfo: null});
    }

    render() {
        if (this.state.hasError) {
            return (
                <div style={{padding: "20px", color: "red"}}>
                    <h2>An error occurred in the component!</h2>
                    <p><strong>Error details:</strong> {this.state.error?.message}</p>
                    <details style={{whiteSpace: "pre-wrap"}}>
                        {this.state.error?.stack}
                    </details>
                    <div className="text-center mt-2">
                        {/*<Button onClick={() => this.handlerReloadPage()}>Reload Page</Button>*/}
                    </div>
                </div>
            );
        }

        return this.props.children;
    }

}

export default ErrorBoundary;