import {instances} from "../api_axios_settings";
import {rejectTransform, resolveTransform} from "../utils_api";

type ListOfMachinesType = {
    equipment: Array<{ id: string, name: string }>,
    pp: { [key: string]: string },

} | null;

type Response<T> = {
    data: T | null;
    error: string | null
};

const transformResponseGuard = (response: any): Response<any> => {
    if (response && response.error && response.error.length > 0) {
        return {error: response.error, data: null};
    }
    return {
        error: null, data: response
    }
}

export const PartPostprocessorFileApi = {
    getListOfMachines: async (): Promise<Response<ListOfMachinesType>> => {
        try {
            const res = await instances.get('/partppget');
            return transformResponseGuard(resolveTransform(res));
        } catch (error) {
            return transformResponseGuard(rejectTransform(error)); // Обработка ошибки
        }
    },
    getPostProcessorLink: async (
        machineToolId: string,
        ppFormat: string,
        partId: string | number,
        orderId: string | number): Promise<Response<{ link: string }>> => {
        return instances.get(`/partppget/${machineToolId}/${ppFormat}/${orderId}/${partId}`)
            .then(res => {
                return transformResponseGuard(resolveTransform(res))
            })
            .catch(error => transformResponseGuard(rejectTransform(error)))
    }
}