import {INITIAL_FILTER_PARTS_OF_MATERIAL} from "../store/reducers/OrderReducer";
import {getProductionMinDeltaEdgeMaterial} from "./production-selectors";
import {utilBandsFilterByMinDeltaEdgeMaterial} from "../helpers/utilBands/utilBandsFilter";

export const getOrder = (state) => state.order?.order || null;
export const getOrderStatus = (state) => state.order?.order.status || null;
export const getIsOrderStatusEdit = (state) => {
    let status = Number(state.order?.order.status ?? 0)
    return status === 1
}
export const getOrderPart = (state) => state.order?.order?.part || [];
export const getOrderProduct = (state) => state.order?.order?.product || [];
export const getOrderMaterial = (state) => state.order?.order?.material || [];
export const getOrderGoods = (state) => state.order?.order?.goods || [];
export const getOrderMaterialSortByName = (state) => state.order?.order?.material?.sort((a, b) => {
    const lang = localStorage.getItem('lang')
    let a_name = a?.name?.translate?.[lang] ?? a?.name;
    let b_name = b?.name?.translate?.[lang] ?? b?.name;
    if (a_name < b_name) {
        return -1
    }
    if (a_name > b_name) {
        return 1
    }
    return 0
}) || [];

export const getOrderBand = (state) => state.order?.order?.band || [];
export const getOrderBandType = (state) => state.order?.order?.bands_type || [];
export const getFindOrderBandTypeId = (id) => (state) => state.order?.order?.bands_type?.find(item => Number(item.band) === Number(id)) || [];

export const getOrderBandsFilteredByMinDeltaEdgeMaterialAndPartZ = (partZ) => (state) => {
    let minDeltaEdgeMaterial = getProductionMinDeltaEdgeMaterial(state);
    let bands = getOrderBand(state);
    return utilBandsFilterByMinDeltaEdgeMaterial(bands, minDeltaEdgeMaterial, partZ)

}

export const getStatePartId = (id) => state => {
    return state.order?.order?.part?.find(p => Number(p.id) === Number(id)) || false
}

export const getFindMaterialGoodsId = (goods_id = 0) => state => {
    return state?.order?.order?.material?.find((item) => Number(item?.goods_id) === Number(goods_id)) || null
}
export const getFindBandGoodsId = (goods_id = 0) => state => {
    return state?.order?.order?.band?.find((item) => Number(item?.goods_id) === Number(goods_id)) || null;
}
export const getTemplatePaz = state => state?.order?.order?.gr || [];
export const getOrderPartSizeType = state => state?.order?.order?.partSizesType;
export const getOrderPartPreview = state => state?.order?.partsPreview || []
export const getOrderPartIdPreview = (part_id = null) => state => state?.order?.partsPreview?.find(img => Number(img.id) === Number(part_id)) || null
export const getTimesOrderBeforePartSizeType = state => state.order.order.beforeChangePartSize;

export const getSelectedMaterialOfDetailing = state => state?.order?.selectedMaterialGoodsIdOfDetailing;

export const isStageMemoryOrder = state => state?.order?.memoryChangeDtOrder || false;

export const getFilterPartsOfMaterial = state => state?.order?.filterPartsOfMaterial || INITIAL_FILTER_PARTS_OF_MATERIAL;

export const getBatchMachiningHolesForm = state => state?.order?.batchMachiningHolesForm
export const getSaveVersions = state => state?.order?.order?.save_versions || [];

export const getOrderPack = state => state?.order?.pack || null;


export const getOrderMaterialBandsGoodsIds = (state) => {
    let order = state.order?.order
    let material = order?.material?.map(m => m.id) ?? [];
    let band = order?.band?.map(m => m.id) ?? [];
    let goods = order?.goods?.map(m => m.id) ?? []
    return [...material, ...band, ...goods]
}

export const getAdditionalOrderGoodsServiceSelected = (state) => {
    return state?.order?.additional_goods_service || null
}

export const getGoodsServiceOrderPartByFirm = (state) => {
    return state?.order?.additional_goods_service
}
export const getGoodsServiceForPartByFirm = (state) => {
    return state?.order?.additional_goods_service?.GoodsServiceForPartByFirm
}
export const getGoodsServiceForOrderByFirm = (state) => {
    return state?.order?.additional_goods_service?.GoodsServiceForOrderByFirm
}

export const getCalculateGoodsServiceForOrderByFirm = (state) => {
    return state?.order?.order?.goods_service_added_order_firm
}
export const getCalculateGoodsServiceForPartByFirm = (state) => {
    return state?.order?.order?.goods_service_added_part_firm
}

export const getOrderMaterialHasPart = (state) => {
    let order = state?.order?.order;
    if (!Array.isArray((order?.part) || !order?.part?.length)) return []
    return order?.material?.filter(material => order?.part?.some(part => Number(part?.material) === Number(material?.id)))
}

export const findPartByIdInOrder = (partId) => (state) => {
    return state?.order?.order?.part.find(p => Number(p.id) === Number(partId))
}

export const filterPartsByMaterialId = (materialId) => (state) => {
    return state?.order?.order?.part.filter(p => Number(p.material) === Number(materialId))
}
export const getHelpInfoForFrezMove = (state) => state.order.order?.help?.frez_move;
