import React from 'react';
import left from "./trajectoryLeft.png"

interface Props {
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
}

const TrajectoryLeftIcon :React.FC<Props>= (props): JSX.Element | null  => {
    return (
        <img src={left} alt="" {...props}/>
    );
};

export default TrajectoryLeftIcon;