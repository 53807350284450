type UtilBandsFilterByMinDeltaEdgeMaterialType = (bands: Array<any> | null, minDeltaEdgeMaterial: number, partZ: number) => Array<any>;

export const utilBandsFilterByMinDeltaEdgeMaterial: UtilBandsFilterByMinDeltaEdgeMaterialType = (bands, minDeltaEdgeMaterial, partZ) => {
    let result: Array<any> = [];

    // Проверка на корректность входных данных
    if (!Array.isArray(bands) || bands.length === 0) {
        return result;  // Возвращаем пустой массив, если входные данные некорректны
    }

    try {
        // Преобразуем входные данные в числа для точности
        const minSizeWithDelta = Number(partZ) + Number(minDeltaEdgeMaterial);

        // Фильтрация элементов
        result = bands.filter(e => {
            const bandY = Number(e.y);
            return minSizeWithDelta <= bandY;  // Возвращаем только те элементы, которые соответствуют условию
        });

        return result;
    } catch (e) {
        // Логируем ошибку с уточнением контекста
        console.error('Error filtering bands:', e);
        return result;  // Возвращаем пустой массив в случае ошибки
    }
};
