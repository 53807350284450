import React from 'react';
import "./input-style.css"
const InputRadio = ({label , onChange, className, id , checked = false, disabled=false, name='', value}) => {
    const cName = `${className} filed-radio`

    return (
        <label className={cName} htmlFor={id}>
            {label ? label : null}
            <input id={id} type="radio" checked={checked} onChange={onChange}  name={name} value={value} />
            <span className="checkmark"></span>
        </label>
    )
};

export default InputRadio;