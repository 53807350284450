import {MachineToolsFormatType} from "../reducers/MachineToolPostProcessorFilesReducer";

export const MACHINE_TOOL_POST_PROCESSOR_FILES_SET_LIST = 'MACHINE_TOOL_POST_PROCESSOR_FILES/SET_LIST';
// export const MACHINE_TOOL_POST_PROCESSOR_FILES_ = 'MACHINE_TOOL_POST_PROCESSOR_FILES/SET';

export const machineToolPostProcessorFilesSetListAC = (payload: MachineToolsFormatType)=>{
    return{
        type: MACHINE_TOOL_POST_PROCESSOR_FILES_SET_LIST,
        payload: payload
    }
}