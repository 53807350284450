import React from 'react';

const FieldWrap = ({label, children}) => {
    if (!children) return false
    return (
        <div className={'form-field'}>
            {label ? <label className={'label'}> {label}</label> : null}
            {children ? children : null}
        </div>
    );
};

export default FieldWrap;