import { translateLib } from './translate.js'

const xCoordTextPattern = /x1?: ?-?[\d\.]+/;
const yCoordTextPattern = /y1?: ?-?[\d\.]+/;
const xCoordColor = 'green';
const yCoordColor = 'blue';
const whiteTextOutline = 'fill: black; stroke: white; stroke-width: 0.7px; font-weight: bold;';

const svgTemplate = (height, width, params, children) => {
  return `<svg baseProfile="tiny" height="${height}" version="1.2" width="${width}" xmlns="http://www.w3.org/2000/svg" xmlns:ev="http://www.w3.org/2001/xml-events" xmlns:xlink="http://www.w3.org/1999/xlink" ${params}>${children}</svg>\n`
}
const rectTemplate = (x, y, width, height, params) => {
  return `<rect x="${x}" y="${y}" width="${width}" height="${height}" ${params}/>\n`
}
const lineTemplate = (x1, y1, x2, y2, params) => {
  return `<line x1="${x1}" y1="${y1}" x2="${x2}" y2="${y2}" ${params}/>\n`
}
const circleTemplate = (cx, cy, r, params) => {
  return `<circle cx="${cx}" cy="${cy}" r="${r}" ${params}/>\n`
}
const gTemplate = (translateArr, params, children) => {
  let translateSvg = `(${translateArr.join(',')})`
  return `<g transform="translate${translateSvg}" ${params}>${children}</g>\n`
}
const gTemplateOnlyParams = (params, children) => {
  return `<g ${params}>${children}</g>\n`
}
const textTemplate = (children, params) => {
  return `<text ${params}>${children}</text>\n`
}
const tspanTemplate = (params, children) => {
  return `<tspan ${params}>${children}</tspan>\n`
}
const styleTemplate = (children) => {
  return `<style>${children}</style>\n`
}
const scriptTemplate = (children) => {
  return `<script>${children}</script>\n`
}
const pathTemplate = (d, params) => {
  return `<path d="${d}" ${params}/>\n`
}

const backBoreBlind = 'orange';
const backBoreThrough = 'red';
const boreBlind = 'gray';
const boreThrough = 'black';

export const tools = {
  whiteTextOutline: whiteTextOutline,
  xCoordColor: xCoordColor,
  yCoordColor: yCoordColor,

  thickness_1: '1px',
  thickness_2: '2px',
  thickness_3: '3px',

  backBoreBlind: backBoreBlind,
  backBoreThrough: backBoreThrough,
  boreBlind: boreBlind,
  boreThrough: boreThrough,

  troughFront: boreBlind,
  troughBack: backBoreBlind,

  pazBlind: boreBlind,
  pazThrough: boreThrough,
  pazBackBlind: backBoreBlind,
  pazBackThrough: backBoreThrough,

  cutToColor: 'gray',

  srezEdgeColor: 'white',
  srezFrontColor: 'gray',
  srezBackColor: 'orange',

  frontFrezColor: 'black',
  backFrezColor: 'orange',
  verticalMirrorFrezColor: 'red',

  measureColor_1: 'black',
  measureColor_2: 'blue',


  style(css) {
    return styleTemplate(css);
  },

  script(s) {
    let scriptFormatted = scriptTemplate(s);
    return scriptFormatted;
  },

  prepareParams(params = {}) {
    return Object.entries(params).map(e => `${e[0]}="${e[1]}"`).join(' ');
  },

  svg(elements, height, width, params) {
    return svgTemplate(
      height, width, this.prepareParams(params), elements.join('')
    )
  },

  line(x1, y1, x2, y2, params = {}) {
    const defaultParams = {
      stroke: 'black',
      'stroke-width': '1px'
    }
    params = { ...defaultParams, ...params };
    return lineTemplate(
      x1, y1, x2, y2, this.prepareParams(params)
    )
  },

  circle(cx, cy, r, params = {}) {
    let defaultParams = {
      fill: 'none',
      stroke: 'black',
      'stroke-width': '1px'
    };
    params = { ...defaultParams, ...params };
    return circleTemplate(
      cx, cy, r, this.prepareParams(params)
    )
  },

  rect(x, y, width, height, params = {}) {
    let defaultParams = {
      fill: 'none',
      stroke: 'black',
      'stroke-width': '1px',
      rx: '0',
    }
    params = { ...defaultParams, ...params };
    return rectTemplate(
      x, y, width, height, this.prepareParams(params)
    )
  },

  g(elements, params) {
    let defaultParams = {
      translate: [0, 0]
    };
    params = { ...defaultParams, ...params };

    if (params.transform !== undefined) {
      return gTemplateOnlyParams(
        this.prepareParams(params), elements.join('\n')
      );
    }
    return gTemplate(
      params.translate, this.prepareParams(params), elements.join('\n')
    );
  },

  text(t, params) {
    let defaultParams = {
      x: 0,
      y: 0,
      transform: '',
      'font-size': '15px',
      'font-family': 'sans-serif'
    };
    params = { ...defaultParams, ...params };
    params['transform-origin'] = `${params.x} ${params.y}`;

    return textTemplate(
      t, this.prepareParams(params)
    )
  },

  tspan(t, params, returnNothing = false) {
    if (returnNothing) {
      return '';
    }

    return tspanTemplate(
      this.prepareParams(params), t
    )
  },

  path(d, params) {
    return pathTemplate(
      d, this.prepareParams(params)
    );
  },

  toFloat(...a) {
    return a.map(parseFloat);
  },

  isInputFrez(frez) {
    return Boolean(frez && frez.inputs && this.objectIsTrue(frez.inputs))
  },

  getPartEdgeDbId(part, side) {
    let sideEdge = part.edge;

    if (typeof sideEdge !== 'object') {
      return null;
    }

    return sideEdge.db_id;
  },

  getPartEdgeColor(bands, part, side) {
    let dbId = this.getPartEdgeDbId(part, side);
    let partEdge = part.edge.side;
    let color = this.getEdgeColor(bands, dbId, { partEdge: partEdge });
    return color
  },

  isObject(o) {
    return typeof o === 'object';
  },

  objectIsTrue(o) {
    if (typeof o !== 'object' || o === null) {
      return Boolean(o);
    }

    return Boolean(Object.keys(o).length);
  },

  getEdgeColor(bands, dbId, params = {}) {
    let defaultParams = {
      partEdge: null, frezEdge: null, band: null
    };
    params = { ...defaultParams, ...params };

    let { partEdge, frezEdge, band } = params;

    if (band === null) {
      band = this.getBandWithDbId(bands, dbId);
    }

    let color = band.color || 'none';

    let bandZ = null;

    if (Object.keys(band).length && (this.objectIsTrue(partEdge) || this.objectIsTrue(frezEdge))) {
      if (this.objectIsTrue(partEdge)) {
        bandZ = partEdge.t;
      } else if (this.objectIsTrue(frezEdge)) {
        bandZ = band.z;
      } else {
        bandZ = null;
      }

      if (bandZ === null) {
        color = 'none';
      } else {
        bandZ = parseFloat(bandZ);

        if (bandZ <= 0.6) {
          color = 'blue';
        } else if (bandZ <= 1.6) {
          color = 'green';
        } else {
          color = 'red';
        }
      }
    }

    return color;
  },

  getBandWithDbId(bands, dbId) {
    let b = bands.filter(b => String(b.goods_id) === String(dbId));

    if (b.length === 0) {
      return {}
    }

    return b[0];
  },

  rotY(y, partY) {
    return partY - y;
  },

  rnd(number) {
    if (number === null) {
      return null;
    }

    number = parseFloat(number);
    let r = Math.round(number * 10 ** 2) / 10 ** 2;

    if (!(r % 1)) {
      return Math.round(number);
    }
    return r;
  },

  rndOrNumber(number) {
    try {
      return this.rnd(number);
    } catch (e) {
      return 0
    }
  },

  textureDirection(translate, params = {}) {
    let defaultParams = {
      turn: 0,
      hoverShow: 'front',
      typeSvg: null,
      partX: null,
      partY: null,
    };
    params = { ...defaultParams, ...params };
    let { partX, partY } = params;

    let needTurn = [90, 270].includes(params.turn);
    let strokeWidth = {
      'lbl': '5px',
    }[params.typeSvg] || '1px';

    let relation;
    let width = 100;
    let height = 5;

    if (params.typeSvg === 'small') {
      let [a, b] = [partX, partY];

      if (needTurn) {
        [a, b] = [partY, partX];
      }
      relation = Math.min(a / 100, b / 40);
      width = 70 * relation;
      height = 5 * relation;
    } else {
      relation = 1
    }

    let rectParams = {
      fill: 'black',
      stroke: 'none',
      'stroke-width': strokeWidth,
      rx: 3 * relation,
    };

    let centerY = -height / 2;

    let elements = [
      this.rect(-width / 2, centerY, width, height, rectParams),
      this.rect(-width / 2, centerY + height * 2, width, height, rectParams),
      this.rect(-width / 2, centerY - height * 2, width, height, rectParams),
    ]
    let gParams = {
      texture_direction: '',
      translate,
      opacity: '0.3',
      hover_show: params.hoverShow,
    };
    let rotateParams = {
      transform: `rotate(${needTurn ? 90 : 0})`
    }

    return this.g(
      [this.g(elements, rotateParams)],
      gParams
    );
  },

  oneSideSymbol(translate, oneSide, turn) {
    if (!oneSide) {
      return '';
    }
    const hoverShow = 'front';
    let rectParams = { fill: '#f33', stroke: 'none', rx: '3' }

    const [centerX, centerY] = translate;
    let lines = [];
    let spacer = 10;

    if ([90, 270].includes(turn)) {
      let width = 40;
      let height = 5;
      lines = [
        this.rect(-width / 2, 0, width, height, rectParams),
        this.rect(-width / 2, spacer, width, height, rectParams),
        this.rect(-width / 2, -spacer, width, height, rectParams),
      ];

    } else {
      let width = 5;
      let height = 40;
      lines = [
        this.rect(0, -height / 2, width, height, rectParams),
        this.rect(-spacer, -height / 2, width, height, rectParams),
        this.rect(spacer, -height / 2, width, height, rectParams),
      ];
    }

    return this.g(
      lines,
      {
        texture_direction: "",
        translate: translate,
        hover_show: hoverShow,
      }
    )
  },

  operationTable(elements, operationIdBg = false, params = {}) {
    params['class'] = 'none'
    let fontSize = 15;

    return this.g(
      [
        this.rect(-5, -fontSize, 1, 1, { fill: 'white', bg: '' }),
        operationIdBg ? this.rect(-5, -fontSize, 5, fontSize + 4, { fill: 'black', operation_id: '' }) : '',
        elements[0],
        ...elements.slice(1)
      ],
      {
        operation_table: '',
        ...params
      }
    );
  },

  operationIdTspan(t, params = {}) {
    params.style = 'fill: white; font_size: 20px';

    return this.tspan(t, params);
  },

  operationIdText(opId, x, y) {
    let params = {
      x: x,
      y: y,
      'font-size': '20px',
      'class': 'operation_id',
      style: 'fill: black; stroke: white; stroke-width: 0.5px; font-weight: bold;'
    };

    return this.text(opId.toString(), params);
  },

  frezMacRadiusText(r, x, y) {
    r = this.rnd(parseFloat(r));
    let params = {
      x: x,
      y: y,
      'class': 'mac_radius',
      'font-size': '20px',
      style: 'fill: black; stroke: white; stroke-width: 1px; font-weight: bold;'
    };
    return this.text(`R${r}`, params)
  },

  getMaterial(materialId, materials) {
    materialId = materialId.toString();

    for (let m of materials) {
      if (m.goods_id.toString() === materialId.toString()) {
        return m
      }
    }
  },

  cropStr(s, maxRetChars = 30) {
    let chars = maxRetChars - 3;

    if (s.length < 31) return s;

    return s.slice(0, chars) + '...';
  },

  getBandName(band, language) {
    let translations = band.translate || {};
    return translations[language] || band[language] || band.name;
  },

  paintText(text, color) {
    return this.tspan(text, { fill: color });
  },

  paintPart(text, pattern, color) {
    let parts = [...new Set(text.match(pattern))];

    for (let part of parts) {
      let coloredText = this.tspan(part, { fill: color });
      text = text.replace(part, coloredText);
    }

    return text;
  },

  paintCoords(text) {
    let patternsColors = [
      [xCoordTextPattern, xCoordColor],
      [yCoordTextPattern, yCoordColor],
    ];

    for (let [pattern, color] of patternsColors) {
      text = this.paintPart(text, pattern, color);
    }

    return text;
  },

  arrow(x, y, params) {
    let defaultParams = {
      scale: 1,
      rotate: 0,
      fill: 'teal'
    };
    params = { ...defaultParams, ...params };

    let directions = {
      0: 'bt',
      90: 'lr',
      180: 'tb',
      270: 'rl',
    };

    let d = `m ${x - 0.5} ${y} l 0 -29.5 l -3 3 l -0.5 -0.5 l 4 -4 l 4 4 l -0.5 0.5 l -3 -3 l 0 29.5 z`;
    return this.path(
      d,
      {
        arrow: directions[params.rotate],
        base_transform: `scale(${params.scale}) rotate(${params.rotate})`,
        transform: `scale(${params.scale}) rotate(${params.rotate})`,
        x0: x,
        y0: y,
        fill: params.fill,
        'transform-origin': `${x} ${y}`,
      }
    );
  },

  getStartPointTspan(startPoint, translateFunc, params = {}) {
    let defaultParams = {
      x: 0,
      dy: '1.2em',
      asText: false,
      y: null
    };
    params = { ...defaultParams, ...params };

    if (!startPoint) {
      return '';
    }

    let spSides = {
      'lu': translateLib.START_POINT_LU,
      'ld': translateLib.START_POINT_LD,
      'ru': translateLib.START_POINT_RU,
      'rd': translateLib.START_POINT_RD,
    }
    let spSide = spSides[startPoint] || startPoint;
    let content = `${translateFunc(translateLib.START_POINT_NAME)}: ${translateFunc(spSide)}`;

    if (params.asText) {
      let startPointText = this.text(content, { x: params.x, y: params.y });
      return startPointText;
    } else {
      let startPointTspan = this.tspan(content, { x: params.x, dy: params.dy });
      return startPointTspan;
    }
  },

  getVerticalMirrorTspan(translateFunc, params) {
    let defaultParams = {
      x: 0,
      dy: '1.2em',
      asText: false,
      y: null,
      yOffset: null
    };
    params = { ...defaultParams, ...params };
    let asText = params.asText;
    delete params.asText;

    let texts = translateFunc(translateLib.IMAGE_DIFFERS_FOR_USER_CONVENIENCE);
    let ret;

    if (!asText) {
      let imageDiffersTspansParams = {
        x: params.x, dy: params.dy, style: 'fill: red;'
      }

      ret =
        this.tspan(texts[0], imageDiffersTspansParams)
        + this.tspan(texts[1], imageDiffersTspansParams);
    } else {
      ret = [
        this.text(texts[0], { x: params.x, y: params.y, fill: 'red' }),
        this.text(texts[1], { x: params.x, y: params.y + params.yOffset, fill: 'red' })
      ]
    }

    return ret;
  },

  isLargeArc(lastCoord, prevLastCoord, cx, cy) {
    let largeArcFlag = false;
    let [lastX, lastY] = lastCoord;
    let [prevLastX, prevLastY] = prevLastCoord;

    if (prevLastY === lastY) {
      if (prevLastX > lastX && cx < lastX) {
        largeArcFlag = true;
      }
      if (prevLastX < lastX && cx > lastX) {
        largeArcFlag = true;
      }
    } else if (prevLastX === lastX) {
      if (prevLastY < lastY && cy > lastY) {
        largeArcFlag = true;
      } else if (prevLastY > lastY && cy < lastY) {
        largeArcFlag = true;
      }
    }

    return largeArcFlag;
  },

  isDigit(str) {
    if (typeof str === 'number') return true;
    if (typeof str != "string") return false;
    return !isNaN(str) &&
      !isNaN(parseFloat(str))
  },

  getXY(obj, partSizesType, params) {
    let defaultParams = {
      cxcy: false,
    };
    let x, y, cx, cy;

    params = { ...defaultParams, ...params };

    if (partSizesType === 'gabarit') {
      x = obj.x1;
      y = obj.y1;
      cx = obj.cx1;
      cy = obj.cy1;
    } else if (partSizesType === 'saw') {
      x = obj.x;
      y = obj.y;
      cx = obj.cx;
      cy = obj.cy;
    } else if (partSizesType === 'w_pre_joint') {
      x = obj.x2;
      y = obj.y2;
      cx = obj.cx2;
      cy = obj.cy2;
    } else {
      throw Error('partSizesType was not passed')
    }

    x = +x;
    y = +y;
    cx = +cx;
    cy = +cy;

    if (params.cxcy) {
      if ((isNaN(cx) || isNaN(cy)) && partSizesType !== 'saw') {
        return this.getXY(obj, 'saw', params)
      }

      return { cx, cy };
    } else {
      if ((isNaN(x) || isNaN(y)) && partSizesType !== 'saw') {
        return this.getXY(obj, 'saw', params)
      }

      return { x, y };
    }
  },

  setXY(obj, x, y, partSizesType) {
    if (partSizesType === 'gabarit') {
      obj.x1 = x;
      obj.y1 = y;
    } else if (partSizesType === 'saw') {
      obj.x = x;
      obj.y = y;
    } else if (partSizesType === 'w_pre_joint') {
      obj.x2 = x;
      obj.y2 = y;
    } else {
      throw Error('partSizesType was not passed')
    }
  },

  turnOrderInside(data_array) {
    let partSizesType = data_array.partSizesType;
    let sides = 'rlbt';

    for (let part of data_array.part) {
      let turn = part.turn;

      if (!turn) continue;

      let { x: partX, y: partY } = this.getXY(part, partSizesType);
      let partZ = part.z;

      let bores = part.operations.bore || [];
      let troughs = part.operations.trough || [];
      let pazes = part.operations.paz || [];
      let frezes = part.operations.frez || [];
      let cutTos = part.operations.cut_to || [];

      if (turn === 90 || turn === 270) {
        let temp;
        // gabarit
        temp = part.y
        part.y = part.x
        part.x = temp;
        // saw
        temp = part.y1
        part.y1 = part.x1
        part.x1 = temp;
        // w_pre_joint
        temp = part.y2
        part.y2 = part.x2
        part.x2 = temp;
        // for (let [xncId, xncProgram] of Object.entries(part.xnc_program)) {
        //   let { x: xncProgramX, y: xncProgramY } = this.getXY(xncProgram);
        //   this.setXY(xncProgram, xncProgramY, xncProgramX, partSizesType);
        // }
      }

      // rotating bores
      for (let bore of bores) {
        let { x: boreX, y: boreY } = this.getXY(bore, partSizesType);

        if (turn === 270) {
          if (bore.side === 'f' || bore.side === 'bb') {
            this.setXY(bore, partY - boreY, boreX, partSizesType);
          } else if (bore.side === 'l') {
            bore.side = 'b';
            this.setXY(bore, partY - boreY, boreX, partSizesType);
          } else if (bore.side === 'b') {
            bore.side = 'r';
            this.setXY(bore, boreY, boreX, partSizesType);
          } else if (bore.side === 'r') {
            bore.side = 't';
            this.setXY(bore, partY - boreY, partZ - boreX, partSizesType);
          } else if (bore.side === 't') {
            bore.side = 'l';
            this.setXY(bore, boreY, boreX, partSizesType);
          }
        } else if (turn === 180) {
          if (bore.side === 'f' || bore.side === 'bb') {
            this.setXY(bore, partX - boreX, partY - boreY, partSizesType);
          } else if (bore.side === 'l') {
            bore.side = 'r';
            this.setXY(bore, partZ - boreX, partY - boreY, partSizesType);
          } else if (bore.side === 'b') {
            bore.side = 't';
            this.setXY(bore, partX - boreX, partZ - boreY, partSizesType);
          } else if (bore.side === 'r') {
            bore.side = 'l';
            this.setXY(bore, partZ - boreX, partY - boreY, partSizesType);
          } else if (bore.side === 't') {
            bore.side = 'b';
            this.setXY(bore, partX - boreX, partZ - boreY, partSizesType);
          }
        } else if (turn === 90) {
          if (bore.side === 'f' || bore.side === 'bb') {
            this.setXY(bore, boreY, partX - boreX, partSizesType);
          } else if (bore.side === 'l') {
            bore.side = 't';
            this.setXY(bore, boreY, boreX, partSizesType);
          } else if (bore.side === 'b') {
            bore.side = 'l';
            this.setXY(bore, partZ - boreY, partX - boreX, partSizesType);
          } else if (bore.side === 'r') {
            bore.side = 'b';
            this.setXY(bore, boreY, boreX, partSizesType);
          } else if (bore.side === 't') {
            bore.side = 'r';
            this.setXY(bore, partZ - boreY, partX - boreX, partSizesType);
          }
        }
      }

      // rotating pazes
      for (let paz of pazes) {
        let { d_side_start, d_side_end } = paz;

        if (turn === 270) {
          if (paz.side === 'f' || paz.side === 'bb') {
            if (paz.side_from === 'l') {
              paz.side_from = 'b';
            } else if (paz.side_from === 'r') {
              paz.side_from = 't';
            } else if (paz.side_from === 'b') {
              paz.side_from = 'r';
            } else if (paz.side_from === 't') {
              paz.side_from = 'l'
            }
          } else if (paz.side === 'r') {
            paz.side = 't';

            if (paz.side_from === 'l') paz.side_from = 't';
            else if (paz.side_from === 'r') paz.side_from = 'b';

          } else if (paz.side === 't') {
            paz.side = 'l';

            if (paz.side_from === 't') paz.side_from = 'l';
            else if (paz.side_from === 'b') paz.side_from = 'r';

          } else if (paz.side === 'l') {
            paz.side = 'b';

            if (paz.side_from === 'l') paz.side_from = 't';
            else if (paz.side_from === 'r') paz.side_from = 'b'

          } else if (paz.side === 'b') {
            paz.side = 'r';

            if (paz.side_from === 't') paz.side_from = 'l';
            else if (paz.side_from === 'b') paz.side_from = 'r';

          }
        } else if (turn === 180) {
          paz.d_side_end = d_side_start;
          paz.d_side_start = d_side_end;

          if (paz.side === 'f' || paz.side === 'bb') {
            if (paz.side_from === 'l') paz.side_from = 'r';
            else if (paz.side_from === 'r') paz.side_from = 'l';
            else if (paz.side_from === 'b') paz.side_from = 't';
            else if (paz.side_from === 't') paz.side_from = 'b';

          } else if (paz.side === 'r') {
            paz.side = 'l';

            if (paz.side_from === 'l') paz.side_from = 'r';
            else if (paz.side_from === 'r') paz.side_from = 'l';

          } else if (paz.side === 't') {
            paz.side = 'b';

            if (paz.side_from === 't') paz.side_from = 'b';
            else if (paz.side_from === 'b') paz.side_from = 't';

          } else if (paz.side === 'l') {
            paz.side = 'r';

            if (paz.side_from === 'l') paz.side_from = 'r';
            else if (paz.side_from === 'r') paz.side_from = 'l';

          } else if (paz.side === 'b') {
            paz.side = 't';

            if (paz.side_from === 't') paz.side_from = 'b';
            else if (paz.side_from === 'b') paz.side_from = 't';
          }
        } else if (turn === 90) {
          paz.d_side_start = d_side_end;
          paz.d_side_end = d_side_start;

          if (paz.side === 'f' || paz.side === 'bb') {
            if (paz.side_from === 'l') paz.side_from = 't';
            else if (paz.side_from === 'r') paz.side_from = 'b';
            else if (paz.side_from === 'b') paz.side_from = 'l';
            else if (paz.side_from === 't') paz.side_from = 'r';

          } else if (paz.side === 'r') {
            paz.side = 'b';

            if (paz.side_from === 'l') paz.side_from = 't';
            else if (paz.side_from === 'r') paz.side_from = 'b';

          } else if (paz.side === 't') {
            paz.side = 'r';

            if (paz.side_from === 't') paz.side_from = 'l';
            else if (paz.side_from === 'b') paz.side_from = 'r';

          } else if (paz.side === 'l') {
            paz.side = 't';

            if (paz.side_from === 'l') paz.side_from = 't';
            else if (paz.side_from === 'r') paz.side_from = 'b';

          } else if (paz.side === 'b') {
            paz.side = 'r';

            if (paz.side_from === 't') {
              if (paz.side_from === 't') paz.side_from = 'r';
              else if (paz.side_from === 'b') paz.side_from = 'l';
            }
          }
        }
      }

      // rotating troughs
      for (let trough of troughs) {
        let { x: troughX, y: troughY } = this.getXY(trough, partSizesType);
        let { l, w } = trough;

        if (turn === 270) {
          this.setXY(trough, partY - troughY, troughX, partSizesType);
          trough.w = l;
          trough.l = w;
        } else if (turn === 180) {
          this.setXY(trough, partX - troughX, partY - troughY, partSizesType);
        } else if (turn === 90) {
          this.setXY(trough, troughY, partX - troughX, partSizesType);
          trough.w = l;
          trough.l = w;
        }
      }

      // rotating frezes
      for (let frez of frezes) {
        for (let frez_move of frez.frez_move) {
          let {
            x: moveX,
            y: moveY,
            x1: moveX1,
            y1: moveY1,
            cx: moveCx,
            cx1: moveCx1,
            cy: moveCy,
            cy1: moveCy1,
          } = frez_move;

          if (turn === 270) {
            frez_move.y = moveX;
            frez_move.y1 = moveX1;
            frez_move.x1 = partY - moveY1;
            frez_move.x = partY - moveY;

            if (frez_move.cx1 !== undefined) {
              frez_move.cy = moveCx;
              frez_move.cy1 = moveCx1;
              frez_move.cx1 = partY - moveCy1;
              frez_move.cx = partY - moveCy;
            }
          } else if (turn === 180) {
            frez_move.y = partY - moveY;
            frez_move.y1 = partY - moveY1;
            frez_move.x1 = partX - moveX1;
            frez_move.x = partX - moveX;

            if (frez_move.cx1 !== undefined) {
              frez_move.cy = partY - moveCy;
              frez_move.cy1 = partY - moveCy1;
              frez_move.cx1 = partX - moveCx1;
              frez_move.cx = partX - moveCx;
            }
          } else if (turn === 90) {
            frez_move.y = partX - moveX;
            frez_move.y1 = partX - moveX1;
            frez_move.x1 = moveY1;
            frez_move.x = moveY;

            if (frez_move.cx1 !== undefined) {
              frez_move.cy1 = partX - moveCx1;
              frez_move.cy = partX - moveCx;
              frez_move.cx1 = moveCy1;
              frez_move.cx = moveCy;
            }
          }
        }
      }

      // rotating cut tos
      for (let cutTo of cutTos) {
        let { b, l, r, t } = cutTo;

        for (let side of sides) {
          cutTo[side] = null;
          cutTo[`f_${side}`] = null;
        }

        if (turn === 270) {
          if (r > 0) cutTo.t = r;
          if (t > 0) cutTo.l = t;
          if (l > 0) cutTo.b = l;
          if (b > 0) cutTo.r = b;
        }
        if (turn === 180) {
          if (r > 0) cutTo.l = r;
          if (t > 0) cutTo.b = t;
          if (l > 0) cutTo.r = l;
          if (b > 0) cutTo.b = t;
        }
        if (turn === 90) {
          if (r > 0) cutTo.b = r;
          if (t > 0) cutTo.r = t;
          if (l > 0) cutTo.t = l;
          if (b > 0) cutTo.r = b;
        }
      }

      let arr = ['srez', 'edge'];

      // rotating srezes and edges
      for (let partKey of arr) {
        let newArr = {};

        if (turn === 270) {
          newArr.l = part[partKey].t;
          newArr.b = part[partKey].l;
          newArr.r = part[partKey].b;
          newArr.t = part[partKey].r;
        } else if (turn === 180) {
          newArr.l = part[partKey].r;
          newArr.b = part[partKey].t;
          newArr.r = part[partKey].l;
          newArr.t = part[partKey].b;
        } else if (turn === 90) {
          newArr.l = part[partKey].b;
          newArr.b = part[partKey].r;
          newArr.r = part[partKey].t;
          newArr.t = part[partKey].l;
        } else {
          newArr = part[partKey];
        }

        part[partKey] = newArr;
      }

      // rotating part.additional_frez_info.sides_edge_paint_correction
      const paintCorrections = part.additional_frez_info.sides_edge_paint_correction;
      const newPaintCorrections = {l: [], b: [], r: [], t: []};
      // rotating part.additional_frez_info.sides_edge_paint_correction.b
      for (const correction of paintCorrections.b) {
        const corr = {...correction};

        if (turn === 90 || turn === 270) {
          corr.use = 'y'
          
          if (turn === 90) {
            corr.end = part.y - correction.start;
            corr.start = part.y - correction.end;
            newPaintCorrections.l.push(corr);
          }
          else {
            newPaintCorrections.r.push(corr)
          }
        } else if (turn === 180) {
          corr.end = part.x - correction.start;
          corr.start = part.x - correction.end;
          newPaintCorrections.t.push(corr);
        } else {
          newPaintCorrections.b.push(corr)
        }
      }
      // rotating part.additional_frez_info.sides_edge_paint_correction.r
      for (const correction of paintCorrections.r) {
        const corr = {...correction};

        if (turn === 90 || turn === 270) {
          corr.use = 'x';

          if (turn === 90) {
            newPaintCorrections.b.push(corr);
          } else {
            corr.end = part.x - correction.start;
            corr.start = part.x - correction.end;
            newPaintCorrections.t.push(corr);
          }
        } else if (turn === 180) {
          corr.end = part.y - correction.start;
          corr.start = part.y - correction.end;
          newPaintCorrections.l.push(corr);
        } else {
          newPaintCorrections.r.push(corr);
        }
      }
      // rotating part.additional_frez_info.sides_edge_paint_correction.t
      for (const correction of paintCorrections.t) {
        const corr = {...correction};

        if (turn === 90 || turn === 270) {
          corr.use = 'y';

          if (turn === 90) {
            corr.end = part.y - correction.start;
            corr.start = part.y - correction.end;
            newPaintCorrections.r.push(corr);
          } else {
            newPaintCorrections.l.push(corr);
          }
        } else if (turn === 180) {
          corr.end = part.x - correction.start;
          corr.start = part.x - correction.end;
          newPaintCorrections.b.push(corr);
        } else {
          newPaintCorrections.t.push(corr);
        }
      }
      // rotating part.additional_frez_info.sides_edge_paint_correction.l
      for (const correction of paintCorrections.l) {
        const corr = {...correction};

        if (turn === 90 || turn === 270) {
          corr.use = 'x';

          if (turn === 90) {
            newPaintCorrections.t.push(corr);
          } else {
            corr.end = part.x - correction.start;
            corr.start = part.x - correction.end;
            newPaintCorrections.b.push(corr);
          }
        } else if (turn === 180) {
          corr.end = part.y - correction.start;
          corr.start = part.y - correction.end;
          newPaintCorrections.r.push(corr);
        } else {
          newPaintCorrections.l.push(corr);
        }
      }

      part.additional_frez_info.sides_edge_paint_correction = newPaintCorrections;
    }

    return data_array;
  },

  mirrorOrder(data_array) {
    function getMirVerMirHor(obj) {
      let { mir_ver, mir_hor } = obj;
      [mir_ver, mir_hor] = [mir_ver, mir_hor].map(m => Number(m))
      return [mir_ver, mir_hor];
    }

    function deleteMirVerMirHor(obj) {
      delete obj.mir_ver;
      delete obj.mir_hor;
    }

    let partSizesType = data_array.partSizesType;

    for (let part of data_array.part) {
      let { x: partX, y: partY } = this.getXY(part, partSizesType);
      let partZ = part.z;

      let bores = part.operations.bore || [];
      let troughs = part.operations.trough || [];
      let pazes = part.operations.paz || [];
      let frezes = part.operations.frez || [];
      let cutTos = part.operations.cut_to || [];
      let srez = part.srez;

      // mirroring bores
      for (let bore of bores) {
        let {
          x: boreX,
          y: boreY,
        } = this.getXY(bore, partSizesType);

        let arr33 = ['x', 'y', 'x1', 'y1', 'd', 'cx', 'cy'];

        for (let a3 of arr33) {
          if (bore[`f_${a3}`] !== undefined) delete bore[`f_${a3}`];
        }

        let [mir_ver, mir_hor] = getMirVerMirHor(bore);
        deleteMirVerMirHor(bore);

        if (mir_hor === 1) {
          if (['f', 'bb', 't', 'b'].includes(bore.side)) {
            boreX = partX - boreX;
          } else if (bore.side === 'l') {
            bore.side = 'r';
            boreX = partZ - boreX;
          } else if (bore.side === 'r') {
            bore.side = 'l';
            boreX = partZ - boreX;
          }
        }

        if (mir_ver === 1) {
          if (['f', 'bb', 'l', 'r'].includes(bore.side)) {
            boreY = partY - boreY;
          } else if (bore.side === 'b') {
            bore.side = 't';
            boreY = partZ - boreY;
          } else if (bore.side === 't') {
            bore.side = 'b';
            boreY = partZ - boreY;
          }
        }

        this.setXY(bore, boreX, boreY, partSizesType);
        let { x: newBoreX, y: newBoreY } = this.getXY(bore, partSizesType);

        if (['f', 'bb'].includes(bore.side)) {
          if (bore.start_point === 'lu') {
            this.setXY(bore, newBoreX, partY - newBoreY, partSizesType);
            bore.start_point = 'ld'
          } else if (bore.start_point === 'rd') {
            this.setXY(bore, partX - newBoreX, newBoreY, partSizesType);
            bore.start_point = 'ld';
          } else if (bore.start_point === 'ru') {
            this.setXY(bore, partX - newBoreX, partY - newBoreY, partSizesType);
            bore.start_point = 'ld';
          }
        } else if (['l', 'r'].includes(bore.side)) {
          if (bore.start_point === 'lu') {
            this.setXY(bore, newBoreX, partY - boreY, partSizesType);
            bore.start_point = 'ld';
          } else if (bore.start_point === 'rd') {
            this.setXY(bore, partZ - newBoreX, newBoreY, partSizesType);
            bore.start_point = 'ld';
          } else if (bore.start_point === 'ru') {
            this.setXY(bore, partZ - newBoreX, partY - newBoreY, partSizesType);
            bore.start_point = 'ld';
          }
        } else {
          if (bore.start_point === 'lu') {
            this.setXY(bore, newBoreX, partZ - newBoreY, partSizesType);
            bore.start_point = 'ld';
          } else if (bore.start_point === 'rd') {
            this.setXY(bore, partX - newBoreX, newBoreY, partSizesType);
            bore.start_point = 'ld';
          } else if (bore.start_point === 'ru') {
            this.setXY(bore, partX - newBoreX, partZ - newBoreY, partSizesType);
            bore.start_point = 'ld';
          }
        }
      }

      // ... paz
      for (let paz of pazes) {
        let { d_side_start, d_side_end } = paz;
        let [mir_ver, mir_hor] = getMirVerMirHor(paz);
        deleteMirVerMirHor(paz);

        if (mir_hor === 1) {
          if (['f', 'bb'].includes(paz.side)) {
            if (paz.side_from === 'l') paz.side_from = 'r';
            else if (paz.side_from === 'r') paz.side_from = 'l';
            else if (['t', 'b'].includes(paz.side_from)) {
              if (d_side_end > 0 || d_side_start > 0) {
                paz.d_side_end = d_side_start;
                paz.d_side_start = d_side_end;
              }
            }
          } else if (['l', 'r'].includes(paz.side)) {
            if (paz.side_from === 'l') paz.side_from = 'r';
            else if (paz.side_from === 'r') paz.side_from = 'l';
            if (paz.side === 'l') paz.side = 'r';
            else paz.side = 'l';

          } else if (['t', 'b'].includes(paz.side)) {
            if (d_side_end > 0 || d_side_start > 0) {
              paz.d_side_end = d_side_start;
              paz.d_side_start = d_side_end;
            }
          }
        }

        if (mir_ver === 1) {
          if (['f', 'bb'].includes(paz.side)) {
            if (paz.side_from === 'b') paz.side_from = 't';
            else if (paz.side_from === 't') paz.side_from = 'b';
            else if (['l', 'r'].includes(paz.side_from)) {
              if (d_side_end > 0 || d_side_start > 0) {
                paz.d_side_start = d_side_end;
                paz.d_side_end = d_side_start;
              }
            }
          } else if (['t', 'b'].includes(paz.side)) {
            if (paz.side_from === 't') paz.side_from = 'b';
            else if (paz.side_from === 'b') paz.side_from = 't';

            if (paz.side === 't') paz.side = 'b'
            else paz.side = 't';

          } else if (['l', 'r'].includes(paz.side)) {
            if (d_side_start > 0 || d_side_end > 0) {
              paz.d_side_start = d_side_end;
              paz.d_side_end = d_side_start;
            }
          }
        }
      }
      
      // mirroring troughs
      for (let trough of troughs) {
        let { x: troughXNew, y: troughYNew } = this.getXY(trough, partSizesType);
        let [mir_ver, mir_hor] = getMirVerMirHor(trough);
        deleteMirVerMirHor(trough);

        if (mir_hor === 1) {
          troughXNew = partX - troughXNew;
        }

        if (mir_ver === 1) {
          troughYNew = partY - troughYNew;
        }

        this.setXY(trough, troughXNew, troughYNew, partSizesType);
      }

      // mirroring frezes
      for (let frez of frezes) {
        let [mir_hor, mir_ver] = getMirVerMirHor(frez);
        deleteMirVerMirHor(frez);
        let delNeeded = (mir_hor || 0) + (mir_ver || 0);

        if (delNeeded > 0) {
          if ((mir_hor === 1 || mir_ver === 1) && delNeeded !== 2) {
            let c = frez.frez_move[0].c;

            if ((c !== undefined ? c : 1) === 1) {
              frez.frez_move[0].c = 2;
            } else if (c === 2) {
              frez.frez_move[0].c = 1;
            }
          }

          if (mir_hor === 1) {
            let arr = { y: 'y', y1: 'y1', cy1: 'y1', cy: 'y' };

            for (let [moveKey, moveValue] of Object.entries(frez.frez_move)) {
              for (let [arrKey, arrValue] of Object.entries(arr)) {
                if (moveValue[arrKey] !== undefined) {
                  moveValue[arrKey] = partY - moveValue[arrKey];
                }
              }

              let dir = moveValue.dir;

              if (mir_hor + mir_ver !== 2) {
                moveValue.rs *= -1;

                if ((dir !== undefined ? dir : 0) === 0) {
                  moveValue.dir = 1;
                } else if (moveValue.dir === 1) {
                  moveValue.dir = 0;
                }
              } else {
                moveValue.dir = dir ? 0 : 1;
              }
            }
          }

          if (mir_ver === 1) {
            let arr = { x: 'x', x1: 'x1', cx: 'x', cx1: 'x1' };

            for (let [moveKey, moveValue] of Object.entries(frez.frez_move)) {
              for (let [arrKey, arrValue] of Object.entries(arr)) {
                if (moveValue[arrKey] !== undefined) {
                  moveValue[arrKey] = partX - moveValue[arrKey];
                }
              }

              if (mir_hor + mir_ver !== 2) {
                let dir = moveValue.dir;

                if ((dir !== undefined ? dir : 0) === 0) {
                  moveValue.dir = 1;
                } else if (dir === 1) {
                  moveValue.dir = 0
                }
              } else {
                moveValue.dir = moveValue.dir ? 0 : 1;
              }
            }
          }

          for (let [inputKey, inputValue] of Object.entries(frez.inputs)) {
            if (inputKey !== 'id') {
              delete inputValue[inputKey];
            }
          }
        }
      }

      // mirroring cut tos
      let firstCutTo = cutTos[0] || {};
      let [cutToMirVer, cutToMirHor] = getMirVerMirHor(firstCutTo);

      let firstCutToR = firstCutTo.r
      let firstCutToL = firstCutTo.l
      let firstCutToB = firstCutTo.b
      let firstCutToT = firstCutTo.t

      if (cutToMirHor === 1) {
        firstCutTo.r = firstCutToL;
        firstCutTo.l = firstCutToR;
      }

      if (cutToMirVer === 1) {
        firstCutTo.t = firstCutToB;
        firstCutTo.b = firstCutToT;
      }

      deleteMirVerMirHor(firstCutTo);

      // mirroring srezes
      let [srezMirVer, srezMirHor] = getMirVerMirHor(srez);
      let srezR = srez.r,
        srezL = srez.l,
        srezT = srez.t,
        srezB = srez.b;

      if (srezMirHor === 1) {
        srez.r = srezL;
        srez.l = srezR;
      }
      if (srezMirVer === 1) {
        srez.t = srezB;
        srez.b = srezT;
      }

      deleteMirVerMirHor(srez);

      // mirroring paint correction
      // const paintCorrections = part.additional_frez_info.sides_edge_paint_correction;
      
    }

    return data_array;
  },

  frezStartCursor(x, y, frezId, c, n=undefined) {
    let color = 'red';

    // from the left side
    if (c === 1) {
      color = 'green';
    } else if (c === 2) {
      color = 'purple';
    }

    const width = 70;
    const height = 70;

    // number = ''
    // if n is not None:
    //     number = text(
    //         n, x=20, y=30, font_size=30, fill='red',
    //         additional_show=frez_id, style="visibility: hidden"
    //     )
    let number = '';

    if (n !== undefined) {
      number = this.text(
        n, {
          x: 20, y: 30, 'font-size': '30px', fill: 'red',
          additional_show: frezId, style: 'visibility: hidden;'
        }
      )
    }

    return `<svg 
  x="${x-width/2}" y="${y-height/2}"
  width="${width}" height="${height}" 
  viewBox="0 0 100 100" 
  xmlns="http://www.w3.org/2000/svg"
  hover_show="${frezId}"
>
  <rect x="15" y="15" width="${width}" height="${height}" opacity="0"/>
  <!-- Circle -->
  <circle cx="50" cy="50" r="20" stroke="${color}" stroke-width="2" fill="none" />

  <!-- Vertical Line -->
  <line x1="50" y1="30" x2="50" y2="70" stroke="${color}" stroke-width="2" stroke-dasharray="5,5"/>
  
  <!-- Horizontal Line -->
  <line x1="30" y1="50" x2="70" y2="50" stroke="${color}" stroke-width="2" stroke-dasharray="5,5" />
  
  <!-- Top Vertical Tick -->
  <line x1="50" y1="20" x2="50" y2="30" stroke="${color}" stroke-width="2" />
  
  <!-- Bottom Vertical Tick -->
  <line x1="50" y1="70" x2="50" y2="80" stroke="${color}" stroke-width="2" />
  
  <!-- Left Horizontal Tick -->
  <line x1="20" y1="50" x2="30" y2="50" stroke="${color}" stroke-width="2" />
  
  <!-- Right Horizontal Tick -->
  <line x1="70" y1="50" x2="80" y2="50" stroke="${color}" stroke-width="2" />
  ${number}
</svg>`;
  },

  applyVirtualVerticalMirroringForEdges(operations) {
    // swaps 'b' and 't' operation.side if operation.virtual_vertical_mirror is true
    for (const operation of operations) {
      const verticalMirror = operation.virtual_vertical_mirror;

      if (!verticalMirror) continue;

      let side = operation.side;

      if (side === 't') side = 'b';
      else if (side === 'b') side = 't';

      operation.side = side;
    }

    return operations;
  },

  getDrillCoordFromFrezMove(moves, moveIndex, z, c) {
    const move = {...moves[moveIndex]};
    const newMove = {...move};
    
    for (const partSizesType of ['saw', 'gabarit', 'w_pre_joint']) {
      const current = this.getXY(move, partSizesType)
      const prev = this.getXY(moves[moveIndex-1] || {}, partSizesType);
      const next = this.getXY(moves[moveIndex+1] || {}, partSizesType);
      const prevX = isNaN(prev.x) ? null : prev.x;
      const nextX = isNaN(next.x) ? null : next.x;
      const prevY = isNaN(prev.y) ? null : prev.y;
      const nextY = isNaN(next.y) ? null : next.y;
      
      const drillCoords = this.changeXToFrez(prevX, current.x, nextX, prevY, current.y, nextY, z, c);
      this.setXY(newMove, drillCoords.x, drillCoords.y, partSizesType);

      if (move.type === 'MAC') {
        const circle = this.getXY(move, partSizesType, {cxcy: true})
        const newR = Math.round(
          Math.sqrt(
            (current.x - circle.x)**2 + (current.y - circle.y)**2
          )
        );
        if (partSizesType === 'gabarit') {
          move.r1 = newR;
        } else if (partSizesType === 'saw') {
          move.r = newR;
        } else if (partSizesType === 'w_pre_joint') {
          move.r2 = newR;
        }
      }
    }

    return move;
  },

  changeXToFrez(xPrev, xCurrent, xNext, yPrev, yCurrent, yNext, z, c) {
    let newX = xCurrent;
    let newY = yCurrent;
    let adjust = c === 2? 1 : -1; // correction coefficient
    let dx, dy;

    if (xPrev != null && xNext !== null) { // both points exist
      dx = xNext - xPrev;
      dy = yNext - yPrev;
    } else if (xPrev != null) { // only previous point
      dx = xCurrent - xPrev;
      dy = yCurrent - yPrev;
    } else if (xNext !== null) { // only next point
      dx = xNext - xCurrent;
      dy = yNext - yCurrent;
    } else { // no previous and no next
      return xCurrent; // do nothing
    }

    if (dx !== 0 || dy !== 0) {
      let length = Math.sqrt(dx*dx + dy*dy);
      let nx = dx / length; // normalized movement vector
      let ny = dy / length;
      
      // shift vector is perpendicular to movement vector: (-ny, nx) or (ny, -nx)
      let shiftX = z * adjust * ny; // pick depending on c
      let shiftY = z * adjust * -nx;

      if (shiftX > 0) {
        shiftX = z;
      } else if (shiftX < 0) {
        shiftX = -z
      }

      if (shiftY > 0) {
        shiftY = z;
      } else if (shiftY < 0) {
        shiftY = -z;
      }

      newX += shiftX;
      newY += shiftY;
    }

    return {x: newX, y: newY};
  }
}
