import React, {useCallback, useMemo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {changeClientMaterialValuesAC, cleanMaterialsPartsFormDataAC} from "../../../store/reducers/MaterialsReducer";
import {random} from "../../../helpers/helpers";
import CloseModal from "../../../Icons/Actions/Close";
import {changeOrderMaterialValueDataAC} from "../../../store/reducers/OrderReducer";
import IconArrow from "../../../Icons/IconArrow";
import {getFindMaterialGoodsId} from "../../../selectors/order-selector";
import {getProductionRangeSizeMaterialClient} from "../../../selectors/production-selectors";
import FormAddRestMaterial from "./Form/FormAddRestMaterial";


/** Добавить остаток материала  */
const MaterialParts = ({isAdded, goods_id, getT}) => {
    const [isOpen, setToggleOpen] = useState(false)
    const dispatch = useDispatch();
    const productionRangeSizeMaterialClient = useSelector(getProductionRangeSizeMaterialClient)
    const material = useSelector(getFindMaterialGoodsId(goods_id));
    let partsClient = useSelector((state) => state.materials.clientMaterial.parts);

    const form = useSelector((state) => state.materials.clientMaterialPart);

    const parts = useMemo(() => {
        if (isAdded && goods_id) {
            let materialParts = Array.isArray(material?.parts) ? material.parts : [];
            return [...materialParts]
        }
        return partsClient;
    }, [isAdded, goods_id, material?.parts, partsClient])

    const deleteMaterialPart = useCallback((id) => (event) => {
        const partsWithout = [...parts].filter(e => Number(e.id) !== Number(id));
        if (isAdded && goods_id) {
            dispatch(changeOrderMaterialValueDataAC(goods_id, 'parts', partsWithout))
        } else {
            dispatch(changeClientMaterialValuesAC('parts', partsWithout));
        }
    }, [parts, dispatch, goods_id, isAdded])


    const handleAddingPartsInMaterial = (partValues) => {
        partValues['id'] = '1' + String(random(0, 12, 0))
        const partsWithNewPart = [...parts, partValues];
        if (isAdded && goods_id) {
            dispatch(changeOrderMaterialValueDataAC(goods_id, 'parts', partsWithNewPart))
        } else {
            dispatch(changeClientMaterialValuesAC('parts', partsWithNewPart));
        }
        dispatch(cleanMaterialsPartsFormDataAC())
    }


    return <React.Fragment key={'material-parts'}>
        <Table parts={parts} getT={getT} deleteMaterialPart={deleteMaterialPart} key={'table'}/>
        <div className="material_band_modal_create_header">
            <h3>{getT("Добавить остаток материала")}</h3>
            <IconArrow top={isOpen} onCLickHandler={() => setToggleOpen(!isOpen)}/>
        </div>
        {isOpen && <FormAddRestMaterial initialValues={form} getT={getT} onSubmit={handleAddingPartsInMaterial}
                                        productionRangeSizeMaterialClient={productionRangeSizeMaterialClient}/>}

    </React.Fragment>;
};

const Table = ({parts, getT, deleteMaterialPart}) => {
    const [isOpen, setToggleOpen] = useState(false)
    if (!Array.isArray(parts) || !parts?.length) return null;
    return (
        <div className="mb-2 w-100">
            <div className="material_band_modal_create_header">
                <h3>{getT("Список материалов")}</h3>
                <IconArrow top={isOpen} onCLickHandler={() => setToggleOpen(!isOpen)}/>
            </div>
            {isOpen && <div className="">
                <table className={"table-border"}>
                    <thead>
                    <tr>
                        <th scope="col">{getT("Длина")}</th>
                        <th scope="col">{getT("Ширина")}</th>
                        <th scope="col">{getT("Количество")}</th>
                        <th scope="col">{getT("Id остатка")}</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {parts.map(e => {
                        return <tr key={`material-parts-${e.id}`}>
                            <td>{e.x}</td>
                            <td>{e.y}</td>
                            <td>{e.count}</td>
                            <td>{e.goods_stock_id ? e.goods_stock_id : "-"}</td>
                            <td><CloseModal width={15} height={15} onClick={deleteMaterialPart(e.id)} isBlack={true}/>
                            </td>
                        </tr>;
                    })}
                    </tbody>
                </table>
            </div>}
        </div>
    )
}

export default React.memo(MaterialParts);