import { tools } from './tools.js';
import { measurementsLib } from './measurements.js';
import { translateLib } from './translate.js';

export const foreignFrezes = {
  getCoords(
    move, partX, partY, partSizesType
  ) {
    let { x, y } = tools.getXY(move, partSizesType);
    y = partY - y;
    return [x, y];
  },
  isCornerFrez(frez, partX, partY, partSizesType) {
    let first = frez.frez_move[0];
    let last = frez.frez_move.slice(-1)[0];
    let [firstX, firstY] = this.getCoords(first, partX, partY, partSizesType);
    let [lastX, lastY] = this.getCoords(last, partX, partY, partSizesType);

    let firstXIsSide = [0, partX].includes(firstX);
    let firstYIsSide = [0, partY].includes(firstY);
    let lastXIsSide = [0, partX].includes(lastX);
    let lastYIsSide = [0, partY].includes(lastY);
    return firstXIsSide && lastYIsSide || firstYIsSide && lastXIsSide;
  },
  cornerRoundingInfoHighlight(
    x, y, r, partX, partY, frezId, parentTranslate
  ) {
    return tools.g([
      tools.circle(x, y, r, {
        'stroke-dasharray': '8 4'
      }),
      tools.line(x, -20, x, partY + 20, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      }),
      tools.line(-20, y, partX + 20, y, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      }),
    ], { id: frezId, translate: parentTranslate });
  },
  highlightPointInfo(x, y, partX, partY) {
    return tools.g([
      tools.line(x, -20, x, partY + 20, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      }),
      tools.line(-20, y, partX + 20, y, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      })
    ])
  },
  highlightArcInfo(cx, cy, r, partX, partY) {
    return tools.g([
      tools.frezMacRadiusText(r, cx, cy),
      tools.circle(cx, cy, r, { 'stroke-dasharray': '8 4' }),
      tools.line(cx, -20, cx, partY + 20, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      }),
      tools.line(-20, cy, partY + 20, cy, {
        stroke: 'black',
        'stroke-width': '1px',
        'stroke-dasharray': '8 4',
        'class': 'dash-line'
      })
    ]);
  },
  frezInfoHighlight(
    frez, partSize, parentTranslate, frezHovers, frezId, side, translateFunc,
    operationId, beginCoords, bands, language, partSizesType
  ) {
    return tools.g([
      tools.g([
        tools.operationIdText(operationId, ...beginCoords), ...frezHovers
      ], { translate: parentTranslate }),
      tools.operationTable([
        ...this.createTable(
          frez, partSize, parentTranslate, side, translateFunc, operationId,
          bands, language, partSizesType
        )
      ], true, { table_id: frezId })
    ], { id: frezId })
  },
  highlightArcMaInfo(x0, y0, x, y, r1) {
    let [rX, rY] = [(x0 + x) / 2, (y0 + y) / 2];
    return tools.g([
      tools.frezMacRadiusText(r1, rX, rY)
    ]);
  },
  createTable(
    frez, partSize, parentTranslate, side, translateFunc, operationId, bands,
    language, partSizesType
  ) {
    let T = translateFunc;
    let height = 0;
    let width = 150;
    let fontSize = 15;
    let fontSizePostfix = 'px';
    let offsetFromLeft = 5;
    let offsetFromTop = fontSize;
    let [partX, partX1, partY, partY1] = partSize;
    let content = [];
    let preTranslations = {
      'l': translateLib.FREZ_SIDE_L,
      'r': translateLib.FREZ_SIDE_R,
      'b': translateLib.FREZ_SIDE_B,
      't': translateLib.FREZ_SIDE_T,
      'f': translateLib.FREZ_SIDE_F,
      'bb': translateLib.FREZ_SIDE_BB,
    };
    side = preTranslations[side];
    let band = tools.getBandWithDbId(bands, frez.db_id);
    let bandName = tools.getBandName(band, language);
    let verticalMirror = frez.virtual_vertical_mirror;

    if (operationId !== null) {
      content.push(
        tools.text(
          tools.operationIdTspan(`ID: ${operationId}`, {
            x: offsetFromLeft
          })
        )
      );
      height += fontSize * 1.4;
    }

    if (verticalMirror) {
      let yOffset = fontSize * 1.3;
      content.push(
        tools.getVerticalMirrorTspan(
          T, {
          x: offsetFromLeft,
          asText: true,
          y: height,
          yOffset: yOffset
        }
        )
      );
      height += yOffset * 2;
    }

    content.push(
      tools.text(
        T(translateLib.FREZ_POPUP_NAME), {
        x: offsetFromLeft,
        y: height
      })
    );
    height += fontSize * 1.3;

    if (band && bandName) {
      let bandLetter = band.letter;
      let bandZ = band.z || 0;
      let bandY = band.y || 0;
      content.push(
        tools.text(
          `[${bandLetter}] ${bandY}/${bandZ}`, {
          x: offsetFromLeft,
          y: height
        }
        )
      );
      height += fontSize * 1.3;
      content.push(
        tools.text(
          tools.cropStr(bandName), {
          x: offsetFromLeft,
          y: height
        })
      );
      height += fontSize * 1.3
    }

    content.push(
      tools.text(
        `${T(translateLib.FREZ_SIDE)}: ${T(side)} `, {
        x: offsetFromLeft, y: height
      }
      )
    );

    height += fontSize * .5;

    let startPoint = frez.start_point;
    if (startPoint) {
      let startPointText = tools.getStartPointTspan(
        startPoint, T, { x: offsetFromLeft, y: height }
      );
      content.push(startPointText);
      height += fontSize * .5;
    }

    let msZ = null;
    let wroteC = false;

    for (let move of frez.frez_move) {
      let moveType = move.type;
      let [x, y] = this.getCoords(move, partX, partY, partSizesType);
      let [x1, y1] = [x, y];

      if (verticalMirror) {
        y = partY - y;
        y1 = partY1 - y1;
      }

      let z = move.z;
      z = z === undefined ? msZ : z;
      let c = move.c;
      let d = move.d;

      if (!wroteC && tools.isDigit(c.toString()) && [0, 1, 2].includes(parseInt(c))) {
        height += fontSize * .7;
        content.push(
          tools.text(
            T(translateLib[`FREZ_C_${c}`]), {
            x: offsetFromLeft,
            y: height
          })
        );
        wroteC = true;
        height += fontSize*1.3;

        content.push(
          tools.text(
            `${T(translateLib['FREZ_DIAMETER'])}: ${d} ${T(translateLib['MILIMETERS'])}`, {
            x: offsetFromLeft,
            y: height,
          })
        );

        height += fontSize * .5;
      }

      if (!tools.isDigit(z)) {
        z = 0;
      } else {
        z = parseFloat(z);
      }

      content.push(
        tools.line(
          0, height, width, height, {
          stroke: 'black',
          'stroke-width': '1px',
          'class': 'table-line-separator'
        })
      );

      height += offsetFromTop;

      if (height > 400) {
        // if height too long, cut the content and add '...'
        content.push(
          tools.text(
            '...', {
            x: 0,
            y: height,
            transform: '',
            'font-size': `${fontSize}${fontSizePostfix} `
          })
        );
        break
      }

      [x, y, x1, y1, z] = [
        x, tools.rotY(y, partY), x1, tools.rotY(y1, partY1), z
      ].map(tools.rnd);

      let moveText = '';
      let moveTextVunmirrored = '';

      let moveTextPattern;
      let r, r1;

      /*
      if move.get('podrezka_edge'):
            content += rect(
                x=-1, y=height-font_size*.8, width=10, height=font_size,
                fill='lightgray', stroke='none', row_bg=''
            )
       */

      if (move.podrezka_edge) {
        content.push(
          tools.rect(
            -1, height-fontSize*.8, 10, fontSize, {
              fill: 'lightgray', stroke: 'none', row_bg: ''
            }
          )
        );
      }

      if (['MS', 'ML'].includes(moveType)) {
        if (moveType === 'MS') {
          msZ = z;
        }

        moveTextPattern = (y0, y10) => (
          tools.tspan(`${moveType}: `)
          + tools.tspan(`x: ${x} y: ${y0} `)
          + tools.tspan(`x1: ${x1} y1: ${y10} `)
          + tools.tspan(`z: ${z} `)
        );
        moveText = moveTextPattern(y, y1);

        if (verticalMirror) {
          moveTextVunmirrored = moveTextPattern(
            tools.rnd(partY - y), tools.rnd(partY1 - y1)
          );
        }
      } else if (moveType === 'MAC') {
        let [cx, cy, cx1, cy1, d] = [
          'cx', 'cy', 'cx1', 'cy1', 'dir'
        ].map(a => tools.rnd(move[a]));

        if (cx1 === undefined) {
          cx1 = cx;
        }

        if (cy1 === undefined) {
          cy1 = cy;
        }

        if (verticalMirror) {
          cy = tools.rnd(partY - cy);
          cy1 = tools.rnd(partY1 - cy1);
        }

        let cxText
        if (typeof cx === 'number' && !isNaN(cx)) {
          cxText = `cx:${cx} ` + (cx !== cx1 ? `(${cx1})` : '');
        } else {
          cxText = `cx1:${cx1}`;
        }
        let cyTextPattern;
        if (typeof cy === 'number' && !isNaN(cy)) {
          cyTextPattern = (cy, cy1) => (
            `cy:${cy} ` + (cy !== cy1 ? `(${cy1})` : '')
          );
        } else {
          cyTextPattern = (cy, cy1) => ( `cy1:${cy1}` );
        }
        
        let moveTextPattern = (y, y1, cy, cy1) => (
          `MAC: x: ${x} y: ${y} x1: ${x1} y1: ${y1} ${cxText} ${cyTextPattern(cy, cy1)} `
          + `D: ${d} z: ${z} `
        );
        if (verticalMirror) {
          moveTextVunmirrored = moveTextPattern(
            tools.rnd(partY - y), tools.rnd(partY1 - y1), tools.rnd(partY - cy),
            tools.rnd(partY1 - cy1)
          );
        }
        moveText = moveTextPattern(y, y1, cy, cy1)
      } else if (moveType === 'MA') {
        [x, y, x1, y1, z, d, r, r1] = [
          'x', 'y', 'x1', 'y1', 'z', 'dir', 'r', 'r1'
        ].map(a => tools.rnd(move[a]));
        let moveTextPattern = (y, y1) => (
          `MA: x: ${x} y: ${y} x1: ${x1} y1: ${y1} r: ${r} D: ${d} z: ${z} `
        );
        moveText = moveTextPattern(y, y1);

        if (verticalMirror) {
          moveTextVunmirrored = moveTextPattern(
            tools.rnd(partY - y), tools.rnd(partY1 - y1)
          );
        }
      }

      let moveTextPainted = tools.paintCoords(moveText);
      content.push(
        tools.text(
          moveTextPainted, {
          x: 0,
          y: height,
          'font-size': `${fontSize}${fontSizePostfix} `
        })
      );
      height += fontSize * .5;

      if (verticalMirror) {
        // adding one more line with not mirrored coords
        content.push(
          tools.line(
            0, height, width, height, {
            stroke: 'black',
            'stroke-width': '1px',
            'class': 'table-line-separator'
          })
        );
        height += offsetFromTop;
        content.push(
          tools.text(
            moveTextVunmirrored, {
            x: 0,
            y: height,
            transform: '',
            'font-size': `${fontSize}${fontSizePostfix} `,
            fill: 'red'
          })
        );
        height += fontSize * .5;
      }
    }
    return content;
  },
  getMaxLineLength(
    lastCoord, newCoord, maxLineLength, maxLineCoords
  ) {
    if (!lastCoord.length || !newCoord.length) {
      return [0, []];
    }

    let [x, y] = newCoord;
    let lineLength = this.pointsDistance(newCoord, lastCoord);

    if (lineLength > maxLineLength) {
      maxLineLength = lineLength;
      maxLineCoords = [lastCoord, [x, y]];
    }

    return [maxLineLength, maxLineCoords];
  },
  centerPoint(p1, p2) {
    return this.lineSplitPoint(p1, p2, 1);
  },
  lineSplitPoint(p1, p2, r) {
    let [x1, y1] = p1;
    let [x2, y2] = p2;
    return [(x1 + r * x2) / (1 + r), (y1 + r * y2) / (1 + r)];
  },
  pointsDistance(p1, p2) {
    let [x1, y1] = p1;
    let [x2, y2] = p2;
    return ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** .5;
  },
  foreignFrezes(
    frezes, bands, partX, partY, partX1, partY1, partZ, parentTranslate,
    sideTranslates, measurementOffset, language, typeSvg, needLetterForFrez,
    partSizesType
  ) {
    let typeSmallCartLbl = ['small', 'cart', 'lbl'].includes(typeSvg);

    if (!frezes.length) {
      let border = typeSmallCartLbl ? 'none' : 'black';
      return [tools.rect(0, 0, partX, partY, { stroke: border }), '', []]
    }

    let a = '';
    let hovers = '';
    let measurementsCoords = [];
    let translateFunc = translateLib.translate(language);
    let partCenter = [partX / 2, partY / 2];

    for (let frez of frezes) {
      let operationId = frez.id;
      let side = frez.side;
      let frezId = `frez_${operationId}`;
      let dbId = frez.db_id;
      let band = tools.getBandWithDbId(bands, dbId);
      let edgeColor = tools.getEdgeColor(
        [], dbId, { band: band, frezEdge: true }
      );
      let verticalMirror = frez.virtual_vertical_mirror || false;

      let strokeWidth = 5;
      let frezColor;

      if (edgeColor !== 'none') {
        frezColor = edgeColor;
      } else if (side === 'bb') {
        frezColor = tools.backFrezColor;
      } else {
        frezColor = tools.frontFrezColor;
      }

      let params = {
        fill: 'none',
        stroke: frezColor,
        'stroke-width': `${strokeWidth}px`,
        'stroke-dasharray': '10 6',
        hover_show: frezId
      };
      let drillPathParams = {
        fill: 'none',
        stroke: 'black',
        'stroke-width': `${strokeWidth}px`,
        'stroke-dasharray': '10 6',
        'asdf': "true",
      };
      let fatParams = {
        fill: 'none',
        stroke: 'black',
        'stroke-opacity': 0,
        'stroke-width': '15px',
        'stroke-dasharray': '10 6',
        hover_show: frezId
      };
      let verticalMirrorOutlineParams = {
        ...params,
        stroke: tools.verticalMirrorFrezColor,
        'stroke-width': `${strokeWidth + 7}px`,
        'class': 'vertical-mirror-blink',
      };
      let frezHovers = [];
      let d = '';
      let drillPath = ''
      let beginCoords = [0, 0];
      let lastCoord = [];
      let frezMoveLen = frez.frez_move.length;
      let maxLineLength = 0;
      let maxLineCoords = [];

      const frezStartCursorData = [];

      for (let move of frez.frez_move) {
        let moveType = move.type;
        let maAsMac = move.cx && move.cy && moveType === 'MA';
        let [x, y] = this.getCoords(move, partX, partY, partSizesType)
        let [x1, y1] = this.getCoords(move, partX1, partY1, partSizesType);

        if (verticalMirror) {
          y = partY - y;
          y1 = partY1 - y1;
        }

        if (moveType === 'MS') {
          const msX = x;
          const msY = y;
          const msC = +move.c || 0;

          frezStartCursorData.push([msX, msY, msC]);

          beginCoords = [x, y];
          d += ` M ${x} ${y}`;

          drillPath += ` M ${x} ${y}`;

          frezHovers.push(
            this.highlightPointInfo(x, y, partX, partY)
          );
        } else if (moveType === 'ML') {
          d += ` L ${x} ${y}`;
          frezHovers.push(
            this.highlightPointInfo(x, y, partX, partY)
          );
        } else if (moveType === 'MAC' || maAsMac) {
          let dir_ = Number(move.dir);
          let { cx, cy } = tools.getXY(move, partSizesType, { cxcy: true });
          cy = partY - cy;

          if (verticalMirror) {
            cy = partY1 - cy;
            dir_ = !dir_;
          }

          let r = ((x - cx) ** 2 + (y - cy) ** 2) ** .5;

          let curveType = move.curve_type;
          let largeArcFlag = curveType === 'big';
          d += ` A ${r} ${r} 0 ${Number(largeArcFlag)} ${Number(!dir_)} ${x} ${y}`;

          drillPath += ` A ${r} ${r} 0 ${Number(largeArcFlag)} ${Number(!dir_)} ${x} ${y}`;

          frezHovers.push(this.highlightArcInfo(cx, cy, r, partX, partY))
        } else if (moveType === 'MA') {
          let r = move.r;
          let dir_ = Number(move.dir);
          d += ` A ${r} ${r} 0 0 ${Number(!dir_)} ${x} ${y} `;
          
          drillPath += ` A ${r} ${r} 0 0 ${Number(!dir_)} ${x} ${y} `;
          
          frezHovers.push(this.highlightArcMaInfo(...lastCoord, x, y, r))
        }
        [maxLineLength, maxLineCoords] = this.getMaxLineLength(
          lastCoord, [x, y], maxLineLength, maxLineCoords
        );
        lastCoord = [x, y];

        if (frezMoveLen > 2) {
          if (x < 0 || x > partX) continue;
          if (y < 0 || y > partY) continue;
        }

        measurementsCoords.push(
          measurementsLib.measurementCoords(
            x, y, x1, y1, partX, partY, partX1, partY1, partZ, 'f',
            parentTranslate, sideTranslates, measurementOffset,
            { measureId: frezId }
          )
        )
      }

      if (verticalMirror) {
        a += tools.path(d, verticalMirrorOutlineParams);
      }

      a += tools.path(d, params);
      a += tools.path(d, fatParams);
      a += tools.path(drillPath, drillPathParams);

      // drawing frez edge (band) letter
      if (needLetterForFrez && band && maxLineCoords && maxLineLength) {
        let letterKwargs = {
          'dominant-baseline': 'middle',
          'text-anchor': 'middle',
          'style': tools.whiteTextOutline,
          'line-height': '7px',
        };

        let letterSize, letterOffset, letterMiss;

        if (typeSmallCartLbl) {
          letterSize = '120px';
          letterOffset = 100;
          letterMiss = 13;
        } else {
          letterSize = '30px';
          letterOffset = 40;
          letterMiss = 7;
        }

        let letter = band.letter;
        let lineCenter = this.centerPoint(...maxLineCoords);

        let partCenterDistance = this.pointsDistance(
          lineCenter, partCenter
        ) || 1;
        let [tx, ty] = this.lineSplitPoint(
          lineCenter, partCenter, letterOffset / partCenterDistance
        );
        tx += letterMiss;
        a += tools.text(
          letter,
          {
            x: tx,
            y: ty,
            'font-size': `${letterSize}px`,
            frez_text: '',
            ...letterKwargs,
          });
      }

      hovers += this.frezInfoHighlight(
        frez, [partX, partX1, partY, partY1], parentTranslate, frezHovers,
        frezId, side, translateFunc, operationId, beginCoords, bands,
        language, partSizesType
      );

      for (const [i, [msX, msY, msC]] of frezStartCursorData.entries()) {
        a += tools.frezStartCursor(msX, msY, frezId, msC, i+1);
      }
    }
    return [a, hovers, measurementsCoords]
  }
}
