import React from 'react';
import center from "./trajectoryCenter.png"

interface Props {
    width?: number;
    height?: number;
    onClick?: () => void;
    className?: string;
}

const TrajectoryCenterIcon :React.FC<Props>= (props): JSX.Element | null  => {
    return (
        <img src={center} alt="" {...props}/>
    );
};

export default TrajectoryCenterIcon;