import {AnyAction} from "redux";
import {MACHINE_TOOL_POST_PROCESSOR_FILES_SET_LIST} from "../actions/machine-tool-post-processor-files-action";

export type MachineToolsFormatType = {
    equipment: Array<{ id: string, name: string }>,
    pp: { [key: string]: string },
} | null;

export interface IMachineToolPostProcessorFiles {
    machineTools: MachineToolsFormatType
}

const InitialState: IMachineToolPostProcessorFiles = {
    machineTools: null
}


const machineToolPostProcessorFilesReducer = (state = InitialState, action: AnyAction) => {
    switch (action.type) {
        case MACHINE_TOOL_POST_PROCESSOR_FILES_SET_LIST: {
            return {
                ...state,
                machineTools: action.payload
            }
        }
        default: {
            return state
        }
    }
}
export default machineToolPostProcessorFilesReducer;