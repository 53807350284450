import {getOrderMaterial} from "./order-selector";

export const getOpeningDetailsFromPartDT = (state)=> state.operations.openingDetailsFromPart || {partId: null, goodsId: null};

export const getOperationPazForm = state => state.operations.paz
export const getOperationFrezNewgo = state => state.operations.frezNewgo

export const getOperationSide = state => state.operations.operationSide;
export const getOperationsEntrySide = state => state?.operations?.operationSides || null;
export const getOperationsSelected = state => state?.operations?.operationsSelected || {}
export const getOperationsFilter = state => state?.operations?.operationsFilter || {}
export const getOperationsPartsError = state => state?.operations?.parts_error || null
export const getOperationsTools = state => state?.operations?.tools || null

export const getMaterialForOperationPage  = (state)=>{
    const currentPart = state.modal.operations.part;
    if (!currentPart?.material) return null; // Если material отсутствует, возвращаем null
    const materialProject = getOrderMaterial(state);
    const result = materialProject?.find(m=> Number(m.goods_id) === Number(currentPart.material)) || null;;
    return result;
}


