
export const getAuthLookupSelected = state=> state.auth.auth_lookup;
export const getAuthUserFirms = state=> state.auth?.userFirms;
export const getRegisterSuccess = state=> state.auth?.registration;
export const getAuthUser = state=> state.auth?.user;
export const getAuthNetworkSocialLoginSelected = state=> state?.auth?.auth_network_social_login;
export const getIsManagerSelected = state => {
    let client_id = state?.auth?.user?.client_id;
    return !!client_id
}
export const getManagerInfo = state => state.auth?.manager;