import {IFrezNewgoOperation} from "../../types/ExtentdsFrezOperaions/FrezOperationsTypes";

export const OPERATIONS_SET_FREZ_NEWGO = "OPERATIONS/SET_FREZ_NEWGO";

type FrezNewgoActionPayloadType = {
    frez?: IFrezNewgoOperation | null | undefined,
    errors?: string[] | null | undefined,
    messages?: string[] | null | undefined,
}
export const operationSetFrezNewgoAction = (payload: FrezNewgoActionPayloadType) => {
    return {
        type: OPERATIONS_SET_FREZ_NEWGO,
        payload: payload
    }
}
