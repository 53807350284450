import {
    EditOperationFrezMoveModalType,
    FrezMoveAddModalT,
    FrezMoveTypesModalT
} from "../reducers/extendedFrezOperations/interfaceExtendedFrezStore";

export const FREZ_OPERATION_CREATE_MODAL = "EXTENDED_FREZ_MOVE/CREATE_MODAL"
export const FREZ_MOVE_EDIT_MODAL = "EXTENDED_FREZ_MOVE/EDIT_MODAL"
export const FREZ_MOVE_TYPES_MODAL = "EXTENDED_FREZ_MOVE/TYPES_MODAL"
export const FREZ_MOVE_ADD_MODAL = "EXTENDED_FREZ_MOVE/ADD_MODAL"

export const frezOperationCreateModalAC = (isOpen: boolean) => {
    return {
        type: FREZ_OPERATION_CREATE_MODAL,
        payload: {isOpen: isOpen}
    }
}

// {
//     isOpen,
//         frez,
//         type,
//         indexFrezEdit
// }
export const frezMoveEditModalAC = (payload: EditOperationFrezMoveModalType) => {
    return {
        type: FREZ_MOVE_EDIT_MODAL,
        payload: payload
        // payload: {isOpen: isOpen, frez: frez, type: type, indexFrezEdit: indexFrezEdit}
    }
}


export const frezMoveTypesModalAC = ({
                                         isOpen,
                                         frez
                                     }: FrezMoveTypesModalT) => {
    return {
        type: FREZ_MOVE_TYPES_MODAL,
        payload: {isOpen: isOpen, frez: frez}
    }
}


export const frezMoveAddModalAC = ({
                                         isOpen,
                                         frez, type
                                     }: FrezMoveAddModalT) => {
    return {
        type: FREZ_MOVE_ADD_MODAL,
        payload: {isOpen: isOpen, frez: frez, type: type}
    }
}